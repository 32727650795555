@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
}

/* header */

.material-icons {
    color: rgb(96, 96, 96);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 15px;
}

.header__left {
    display: flex;
    align-items: center;
}

.header__left img {
    width: 100px;
    margin-left: 15px;
}

.header i {
    padding: 0 7px;
    cursor: pointer;
}

.header__search form {
    border: 1px solid #ddd;
    height: 35px;
    margin: 0;
    padding: 0;
    display: flex;
}

.header__search input {
    width: 500px;
    padding: 10px;
    margin: 0;
    border-radius: 0;
    border: none;
    height: 100%;
}

.header__search button {
    padding: 0;
    margin: 0;
    height: 100%;
    border: none;
    border-radius: 0;
}

/* Sidebar */
.mainBody {
    height: calc(100vh - 70px);
    display: flex;
    overflow: hidden;
}

.sidebar {
    height: 100%;
    width: 230px;
    background-color: white;
    overflow-y: scroll;
}

.sidebar__categories {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    margin-top: 15px;
}

.sidebar__category {
    display: flex;
    align-items: center;
    padding: 12px 25px;
}

.sidebar__category span {
    margin-left: 15px;
}

.sidebar__category:hover {
    background: #e5e5e5;
    cursor: pointer;
}

.sidebar::-webkit-scrollbar {
    display: none;
}

hr {
    height: 1px;
    background-color: #e5e5e5;
    border: none;
}

/* videos */

.videos {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 15px 15px;
    border-top: 1px solid #ddd;
    overflow-y: scroll;
}

.videos__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.video {
    width: 310px;

    margin-bottom: 30px;
}

.video__thumbnail {
    width: 100%;
    height: 170px;
}

.video__thumbnail img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.author img {
    object-fit: cover;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.video__details {
    display: flex;
    margin-top: 10px;
}

.title {
    display: flex;
    flex-direction: column;
}

.title h3 {
    color: rgb(3, 3, 3);
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 6px;
}

.title a,
span {
    text-decoration: none;
    color: rgb(96, 96, 96);
    font-size: 14px;
}

h1 {
    font-size: 20px;
    margin-bottom: 10px;
    color: rgb(3, 3, 3);
}

@media (max-width: 425px) {
    .header__search {
        display: none;
    }

    .header__icons .material-icons {
        display: none;
    }

    .header__icons .display-this {
        display: inline;
    }

    .sidebar {
        display: none;
    }
}
#views {
    padding-left: 225px;
    font-size: 18px;
}

#like_buttons {
    padding-left: 290px;
    float: right;
}

.user_views {
    background-color: white;
    margin: 5px 10px 5px 10px;
    padding-top: 1px;
    /* padding-left: 16px; */
    padding-bottom: 10px;
    /* border: red solid; */
    /* padding-right: 16px; */
    width: 95%;
}
@media (max-width: 768px) {
    body {
        overflow-y: hidden;
    }
    .header__search {
        display: none;
    }

    .sidebar {
        display: none;
    }

    .show-sidebar {
        display: inline;
        position: fixed;
        top: 4.4rem;
        height: auto;
    }

    #views {
        padding-left: 18vw;
        font-size: 18px;
    }

    #like_buttons {
        padding-left: 18vw;
        float: right;
    }

    /* .user_views .parag {
        width: 100vw;
    } */
}

@media (max-width: 941px) {
    .header__search input {
        width: 300px;
    }
}

@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
@import 'https://fonts.googleapis.com/css?family=Roboto:400,700';

.btn {
    background-color: #dbdbdb;
    color: #777777;
    padding: 0px 16px;
    font-size: 15px;
    cursor: pointer;
    border-style: none;
    height: 29px;
}

.content {
    width: 75%;
    display: flex;
    align-content: center;
}

.othervids {
    width: 400px;
    padding-left: 10px;
    margin-left: 12px;
    background-color: white;
    margin-bottom: 20px;
}

body {
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
}

#video {
    flex-direction: row;
    margin-bottom: 10px;
    width: auto;
}

#maincontainer {
    /* overflow-y: scroll; */
    display: flex;
}

@media (max-width: 768px) {
    #maincontainer {
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }
    .othervids {
        width: 95%;
    }
}

.thumbnails {
    display: flex;
    margin-bottom: 10px;
}

.thumb_summary {
    padding-left: 8px;
    margin-right: 12px;
    align-items: center;
}

#userinfo {
    display: flex;
    background-color: white;
    padding: 10px;
}

#subscribe_button {
    width: 80px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    background-color: black;
    border-radius: 20px;
    text-align: center;
    color: white;
    padding-top: 5px;
    /* padding: 0 auto; */
    /* padding-bottom: 10px; */
    /* border: black solid 3px; */
}

.btn2 {
    background-color: white;
    color: #777777;
    padding: 0px 16px;
    font-size: 15px;
    cursor: pointer;
    border-style: none;
    border-color: #ffffff;
    height: 26px;
}

.btn5 {
    background-color: white;
    color: #777777;
    padding-left: 16px;
    padding-right: 3px;
    font-size: 15px;
    cursor: pointer;
    border-style: solid;
    border-color: #ffffff;
}

.btn_upload {
    background-color: white;
    color: #777777;
    padding: 0px 30px;
    font-size: 18px;
    cursor: pointer;
    border-style: none;
    height: 28px;
}

.btn3 {
    background-color: dodgerblue;
    color: #ffffff;
    padding: 0px 10px;
    font-size: 12px;
    border-style: solid;
    border-color: #ffffff;
    width: 80px;
}

#button_section {
    display: flex;
    background-color: white;
}

iframe {
    border: 0;
    width: 650px;
    height: 360px;
}

#navbar {
    display: flex;
}

#youtube-logo {
    padding-top: 5px;
}

.alert {
    background-color: aqua;
}

.language {
    background-color: dodgerblue;
    color: white;
    padding: 6px 0px;
    justify-content: start;
}

.btn_lang {
    background-color: transparent;
    color: white;
    padding-left: 30px;
    font-size: 15px;
    cursor: pointer;
    border-style: none;
}

.btn_lang_close {
    background-color: transparent;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    cursor: pointer;
    border-style: none;
    float: right;
}

.notification {
    background-color: white;
    padding: 2px 16px;
    border-style: solid;
    border-color: dodgerblue;
    margin-bottom: 16px;
}

.profile_photo {
    /* padding-right: 10px; */
    border-radius: 50px;
}

hr {
    border-width: 0.5px;
    background-color: white;
}

select {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    font-size: 10px;
}

h3 {
    margin-top: 0px;
}

.logo {
    padding-right: 16px;
}

#up_next {
    display: flex;
}

.toggle_btn {
    padding: 12px 3px;
    margin-left: 180px;
    font-size: 13px;
}

.show_more {
    text-align: center;
}
